import React, {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {session} from "../api/ApiSession";
import {DEFAULT_PATH, TranslationPortalFile} from "../utils/constants";
import {QueryString} from "../common/api/model/QueryString";
import {Action, Alert, Box, BoxSpacing, Button, ButtonLink, ButtonSize, ButtonStyle, Color, FlexContent, FlexContentDirection, FlexContentSpacing, Footer, HeroHeader, Icon, Paragraph, ParagraphAlignment, SvgSirdataLogoWhite} from "@sirdata/ui-lib";
import {PORTAL} from "../utils/Portal";
import {usePreprod} from "../common/utils/helper";
import {Wrapper} from "../common/component/widget";
import {sirdataCmpPricingUrl} from "../utils/helper";
import {Origin} from "../common/api/model/Origin";
import {pathAccess} from "../common/utils/constants";
import {usePortalContext} from "../common/context/PortalContext";

function Login() {
    const history = useHistory();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textLogin} = useTranslation(TranslationPortalFile.LOGIN);
    const {portalSetting} = usePortalContext();

    const goToAccount = (path: string, params?: {}) => () => {
        const queryParams = QueryString.build({
            [QueryString.ORIGIN]: PORTAL.origin.name,
            ...params
        });
        window.location.href = `${Origin.ACCOUNT.getUrl(usePreprod)}/${path}?${queryParams}`;
    };

    useEffect(() => {
        if (session) {
            (async () => {
                try {
                    const isLogged = await session.isLogged();
                    if (!isLogged) {
                        return;
                    }

                    const account = await session.getAccount();
                    if (!account.hasAccess(PORTAL.origin.name)) {
                        history.push(pathAccess);
                        return;
                    }

                    history.push(DEFAULT_PATH);
                } catch {
                    session.clean();
                }
            })();
        }
    }, [history]);

    const buildProductListItems = () => (
        <div className="login-items">
            <ul>
                {(textLogin("teaser.items", {returnObjects: true}) as string[]).map((item) => (
                    <li key={item}>{item}</li>
                ))}
            </ul>
            <div className="login-items__certifications">
                {(textLogin("teaser.certifications", {returnObjects: true}) as { name: string; image: string }[]).map(({name, image}) => (
                    <img src={image} title={name} alt={name} key={name}/>
                ))}
            </div>
        </div>
    );

    const buildFooter = () => (
        <Footer
            legalNotice={portalSetting.footer.legal_notice}
            cookiesSettings={portalSetting.footer.cookies_settings}
            items={portalSetting.footer.items.map((item) => ({label: item.label, path: item.path}))}
        />
    );

    return (
        <Wrapper>
            <div className="login-button-container">
                <Button style={ButtonStyle.PRIMARY_WHITE} onClick={goToAccount("login")}>
                    {textLogin("login.buttons.login")}
                </Button>
            </div>
            <div className="login-left">
                <div className="login-left-content">
                    <div className="main-logo">
                        <SvgSirdataLogoWhite/>
                    </div>
                    <h1 className="login-title">{textLogin("teaser.title")}</h1>
                    {buildProductListItems()}
                </div>
            </div>
            <div className="login-right">
                <div className="login-right__content">
                    <HeroHeader title={textLogin("login.title")} cssClass="login-right__content__hero-title"/>
                    <div className="login-right__content__separator">
                        <Icon name="more_vert" colorIcon={Color.GREY}/>
                    </div>
                    <div className="login-right__content__pricing">
                        <Box spacing={BoxSpacing.LARGE} cssClass={"login-right__content__pricing__item"}>
                            <div className="login-right__content__pricing__item__tax">
                                <span>{t("pricing.premium.price_before")}</span>
                                <span className="login-right__content__pricing__item__tax__price">{t("pricing.premium.price")}</span>
                                <span>{t("pricing.premium.price_after")}</span>
                            </div>
                            <Alert text={t("pricing.free_trial")}/>
                            <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL}>
                                {(t("pricing.premium.details", {returnObjects: true}) as string[]).map((it) =>
                                    <Paragraph key={it} alignment={ParagraphAlignment.JUSTIFY} cssClass="login-right__content__pricing__item__details">
                                        <Icon name="east"/>
                                        {it}
                                    </Paragraph>
                                )}
                            </FlexContent>
                            <Button
                                size={ButtonSize.BIG}
                                style={ButtonStyle.PRIMARY_GREEN}
                                icon={{name: "east"}}
                                reverse
                                onClick={goToAccount("register", {premium: 1})}
                            >
                                {textLogin("login.buttons.start")}
                            </Button>
                        </Box>
                        <div className="login-right__content__pricing__buttons">
                            <ButtonLink
                                cssClass="login-right__content__see-more"
                                icon={Action.OPEN.icon}
                                reverseUnderline
                                onClick={() => window.open(sirdataCmpPricingUrl, "_blank")}
                            >
                                {t("pricing.action.see_more").toUpperCase()}
                            </ButtonLink>
                            <Button
                                size={ButtonSize.SMALL}
                                style={ButtonStyle.DEFAULT_GREEN}
                                icon={{name: "east"}}
                                reverse
                                onClick={goToAccount("register")}
                            >
                                {t("pricing.action.register_freemium")}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="login-right__footer">
                    {buildFooter()}
                </div>
            </div>
            <div className="login-bottom">
                {buildProductListItems()}
                {buildFooter()}
            </div>
        </Wrapper>
    );
}

export default Login;
